body {
    height: 100%;
}
.home {
    display: flex;
    align-items: center;
    height: 100vh;
    position: absolute;
}

.hero-image{
    margin: 20px;
    margin-right: 60px; 
}

img {
    width: 100%;
    border-radius: 30px;
    box-shadow: 10px 5px 5px rgb(45, 49, 48);
    max-inline-size: 100%;
    block-size: auto;
}

.greeting{
    margin-right: 100px;
    line-height: 50px;
    color:#2d545e;
    padding: 0 3em;
  }

.greeting .job_title {
  font-size: 2em;
}

.greeting h3{
    font-size: xx-large;
}

.greeting h1{
    font-size: xxx-large;
}

.greeting p{
    font-size: x-large;
}


ul {
    list-style-type: none;
}

.navigation{
    position: fixed;
    right: 30px;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
    top: 100px;
    opacity: 1;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
}

ul {
    list-style-type: none;
}

 .icon-box a{
    display: block;
    padding: 0;
    width: 50px;
    height: 50px;  
}

li.icon-box{
    width: 50px;
    height: 50px;
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    transition: .3s;
    margin: 20px 0;
    border-radius: 50%;
    background: #2d545e;
}

.icons{
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 25px;
    color: gray;
}

 li.icon-box.active,li.icon-box:hover, li.icon-box.active .icons, li.icon-box:hover .icons {
    color: #fff;
  }

  .icon-box h2{
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    color: #fff;
    line-height: 50px;
    font-weight: 500;
    transition: all .3s;
    border-radius: 30px;
    text-transform: uppercase;
    padding: 0 25px 0 30px;
    height: 50px;
}

  .icon-box a:hover h2 {
    opacity: 1;
    right: 27px;
    margin: 0;
    text-align: center;
    border-radius: 30px 0 0 30px;
    background-color: #2d545e;
  }

.particles{
    overflow-y: hidden;
    height: 100vh;
  } 

  .download{
    width: 15rem;
    line-height: 2em;
    text-align: center;
    border-radius: 26px;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 10px 5px 5px rgb(45, 49, 48);
    text-decoration: none;
    color: darkslategrey;
    background-color: whitesmoke;
    border: 1px solid gray;
  }

  .download span {
      font-size: larger;
      margin: 5px 10px;
  }
  .download:hover{
      color:white;
      transition: .7s;
      background-color: teal;
      cursor: pointer;
  }

  .downloadIcon{
      font-size: x-large;
      display: none;
  }
  
  .download:hover .downloadIcon{
      display: inline;
  }

  .menu-wrap {
      display: none;
  }

  @media(max-width: 991px){
    :root {
        --primary-color: rgba(0, 42, 54, 0.75);
        --overlay-color: rgba(24, 39, 51 , 0.85);
        --menu-speed: 0.75s;
      }
    .menu-wrap {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        display: inline;
      }
      
      .menu-wrap .toggler {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        cursor: pointer;
        width: 50px;
        height: 50px;
        opacity: 0;
      }
      
      .menu-wrap .hamburger {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 40px;
        height: 40px;
        padding: 1rem;
        background: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .menu-wrap .hamburger > div {
        position: relative;
        flex: none;
        width: 100%;
        height: 2px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease;
      }
      
      .menu-wrap .hamburger > div::before,
      .menu-wrap .hamburger > div::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: -10px;
        width: 100%;
        height: 2px;
        background: inherit;
      }

      .menu-wrap .hamburger > div::after {
        top: 10px;
      }
      
      .menu-wrap .toggler:checked + .hamburger > div {
        transform: rotate(135deg);
      }
      
      .menu-wrap .toggler:checked + .hamburger > div:before,
      .menu-wrap .toggler:checked + .hamburger > div:after {
        top: 0;
        transform: rotate(90deg);
      }
      
      .menu-wrap .toggler:checked:hover + .hamburger > div {
        transform: rotate(225deg);
      }
      
      .menu-wrap .toggler:checked ~ .menu {
        visibility: visible;
      }
      
      .menu-wrap .toggler:checked ~ .menu > div {
        transform: scale(1);
        transition-duration: var(--menu-speed);
      }
      
      .menu-wrap .toggler:checked ~ .menu > div > div {
        opacity: 1;
        transition:  opacity 0.4s ease 0.4s;
      }
      
      .menu-wrap .menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .menu-wrap .menu > div {
        background: var(--overlay-color);
        width: 200vw;
        height: 100%;
        display: flex;
        flex: none;
        align-items: center;
        justify-content: center;
        transform: scale(0);
        transition: all 0.4s ease;
      }
      
      .menu-wrap .menu > div > div {
        text-align: center;
        max-width: 90vw;
        max-height: 100vh;
        opacity: 0;
        transition: opacity 0.4s ease;
      }
      
      .menu-wrap .menu > div > div > ul > li {
        list-style: none;
        color: #fff;
        font-size: 1.5rem;
        padding: 1rem;
      }
      
      .menu-wrap .menu > div > div > ul > li > a {
        color: inherit;
        text-decoration: none;
        transition: color 0.4s ease;
      }

      .home{
        display: inline;
        text-align: center;
    }

    .hero-image{
        width: 50%;
        margin: 70px auto;
    }

    img {
        border-radius: 50%;
    }
    .particles{
        display: none;
    }

    .greeting{
        text-align: center;
        margin: auto;
    }

    .greeting h3{
      font-size: x-large;
  }
  
    .greeting h1{
      font-size: x-large;
      line-height: 0.85;
      text-decoration: underline;
  }

  .greeting p{
      margin: 20px;
      font-size: larger;
      text-align: justify;
      line-height: 2;
      word-break: break-all;
  }
  .download{
      margin: 30px auto;
  }
      
      
    .navbar{
        display: none;
    }
  }

  @media(max-width: 576px){
    .hero-image{
        width: 60%;
        margin: 70px auto;
    }

  }
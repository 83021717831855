.hire-me {
    display: flex;
    justify-content: center;
  }
  
  label {
    display: block;
    color: #717981
  }
  
  input {
    line-height: 2em;
    color: #717981;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    border-color: #e2e2e2;
    background-color: rgb(237, 239, 240);
  }
  
  textarea {
    line-height: 1.5em;
    color: #717981;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    border-color: #717981;
    background-color: rgb(237, 239, 240);
  }
  
  .form-button {
    text-align: center;
      display: block;
      margin: 0 auto;
  }
  
  .btn {
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    color: #717981;
    text-align: center;
    border-color: gray;
  }
  .btn:hover {
    background-color: #145f7c;
    transition: 2s;
    color: white;
  }
  
  .form-container {
    padding: 20px;
  }
  
  .form-input {
    margin: 20px;
  }
  
  .form-input label {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: x-large;
  }

  @media(max-width: 570px){
  
    .form-container{
      padding: 10px;
      width: 100%;
    }
    .form-input {
      margin: 10px;
    }
    .form-input label{
      font-size: medium;
    }
  }
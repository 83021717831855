.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: rgb(92, 212, 202);
  position: relative;
  margin: auto;
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;
  text-align: center;
  top: 30%;
  border:1px solid darkgray;
  
}

.connect-header{
  font-size: xxx-large;
  margin-bottom: 20px;
}

.modal-header {
  position: absolute;
  top: 0;
  right:0;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .3;
  cursor: pointer;
  border: none;
}

button {
  font-size: .9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: .3rem 1rem;
  margin-left: .5rem;
}

.button-default {
  background: #247BA0;
  color: #fff;
}

.connect-wrapper h3{
  margin-bottom: 20px;
  font-size: x-large;
}

.connect-wrapper p{
  margin-bottom: 20px;
  font-size: 1.3em;
  text-align: justify;
  line-height: 1.6;
}

.connect-wrapper ul{
  display: flex;
  justify-content: center;
}

.connect-wrapper li{
  margin: 5px;
  font-size: 1.7em;
  padding: 2px;
}

.connect-wrapper a{
  text-decoration: none;
  color: rgb(10, 1, 1)
}

.project-link {
  text-decoration: none;
  color: white;
  position: relative;
  padding: 2px;
  font-size: x-large;
}

.project-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(170, 37, 37);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.project-link:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
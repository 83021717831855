.top-container{
    text-align: center;
    font-size: 60px;
    font-weight: 800;
    margin: 0 auto;
    position: relative;
    padding: 50px 0;
}

.top-container h2{
    font-size: 56px;
    font-weight: 900;
    color: gray;   
}

.top-container span{
    font-size: 100px;
    left: 0;
    letter-spacing: 10px;
    line-height: 0.7;
    position: absolute;
    right: 0;
    top: 28%;
    text-transform: uppercase;
    font-weight: 800;
    color: rgba(30, 37, 48, 0.07);
}

.middle-container{
    display:flex;
}

.middle-container .left-container{
    width: 50%;
    margin: 20px;
    font-size: x-large;
    line-height: 2;
    color: rgb(121, 8, 8);
    border-right: 3px solid rgba(30, 37, 48, 0.07);
}

.middle-container .right-container{
    width: 50%;
    margin: 20px;
    font-size: x-large;
    line-height: 2;
    color: rgb(121, 8, 8);
}

.middle-container span{
    font-size: larger;
    color:rgb(133, 39, 15)
}

.middle-container .right-container {
    display: flex;
    align-items: center;
}

.middle-container .right-container .left-side li span:first-child{
    color: gray;
}

.middle-container .right-container .right-side{
    margin-left: 5px;
}

.middle-container .right-container .right-side li{
    display: flex;
    margin: 25px;
    justify-content: left;
    align-items: center;
    font-size: xx-large;
}


.middle-container .right-container .right-side li :hover{
    color: rgba(0, 0, 0, 0.582)
}

.middle-container .right-container .right-side a {
    text-decoration: none;
    color: darkgray;
}

.aboutIcon{
    margin: 10px;
    color: darkgrey;
}

.linkedin{
    color:#2867B2;
}

.github{
    color:#24292e;
}

.twitter {
    color:#1DA1F2;
}

.bottom-container{
    text-align: center;
}

.bottom-container h2 {
    font-size: 40px;
    font-weight: 800;
    color: gray; 
    margin-bottom: 15px;  
}

@media(max-width: 991px){

    .top-container h2{
        font-size: 40px;
    }
    .top-container span {
        font-size: 60px;
        letter-spacing: 5px;
        top: 36%;
    }

    .middle-container{
        display:inline-block;
    }

    .middle-container .left-container{
        width: 90%;
        margin: 35px;
        line-height: 2;
        color: rgb(121, 8, 8);
        border-right: none;
        text-align: justify;
        word-spacing: -3px;

    }

    .middle-container .right-container{
        font-size: 24px;
        width: 80%;
    }

    .middle-container .right-container .right-side{
        display: none;

    }

    .bottom-container{
        width: 100%;
    }
    .bottom-container h3{
        font-size: large;
    }
}

@media(max-width: 480px){

    .top-container h2{
        font-size: 20px;
    }
    .top-container span {
        font-size: 35px;
        letter-spacing: 0.2px;
        top: 40%;
    }

    .middle-container{
        display:inline-block;
    }

    .middle-container .left-container{
        width: 90%;
        margin: 25px;
        line-height: 2;
        color: rgb(121, 8, 8);
        border-right: none;
        text-align: justify;
        word-spacing: -3px;
        font-size: 18px;
        border-bottom: 1px solid rgba(30, 37, 48, 0.07);
    }

    .middle-container .right-container{
        font-size: 15px;
        width: 80%;
    }

    .middle-container .right-container .right-side{
        display: none;

    }

    .bottom-container{
        width: 100%;
    }
    .bottom-container h3{
        font-size: medium;
    }

    .skills-container{
        display: flex;
        flex-wrap: wrap;
    }

    .icons-container{
        width: 30%;
    }
}
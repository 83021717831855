.projects-gallery{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.card{
    width: 25%;
    border-radius: 30px;
    margin: 40px;
    background-color: teal;
    position: relative;
}

.project-image{
    width: 100%;
}

 .hidden-message{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #00eeff;
    transition: opacity .3s ease-in-out;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
} 

.hidden-overlay{
    top: 0;
    left: 0;
    width: 98%;
    height: 95%;
    border-radius: 30px;
}  

.card:hover .hidden-message {
    opacity: 1;
    cursor: pointer;
}

.card .hidden-message span{
    font-size: x-large;
    font-style: oblique;
}

@media(max-width:991px){
    .card{
        width: 45%;
    }
    .hidden-message span{
        display: none;
    }

    .project-image{
        border-radius: 20px;
    }
}
